import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';

const data = {
  data: {
    breadcrumbs: {
      path: '/inicio',
      text: 'Inicio',
    }
  }

}

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/inicio'
  }, {
    path: 'inicio',
    canLoad: [MsalGuard],
    canActivate: [MsalGuard],
    loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule),
  }, {
    path: 'autos',
    loadChildren: () => import('./modules/autos/autos.module').then(m => m.AutosModule),
    canLoad: [MsalGuard],
    canActivate: [MsalGuard],
    data: data
  }, {
    path: 'vida',
    loadChildren: () => import('./modules/life/life.module').then(m => m.LifeModule),
    canLoad: [MsalGuard],
    canActivate: [MsalGuard],
    data: data
  }, {
    path: 'asistencia',
    loadChildren: () => import('./modules/assistance/assistance.module').then(m => m.AssistanceModule),
    canLoad: [MsalGuard],
    canActivate: [MsalGuard],
    data: data
  }, {
    path: 'salud',
    loadChildren: () => import('./modules/health/health.module').then(m => m.HealthModule),
    canLoad: [MsalGuard],
    canActivate: [MsalGuard],
    data: data
  }, {
    path: 'consultas',
    loadChildren: () => import('./modules/queries/queries.module').then(m => m.QueriesModule),
    canLoad: [MsalGuard],
    canActivate: [MsalGuard],
    data: data
  }, {
    path: '**',
    redirectTo: 'inicio'
  }
];

const isIframe = window !== window.parent && !window.opener;

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: !isIframe ? 'enabledNonBlocking' : 'disabled' // Don't perform initial navigation in iframes
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
