import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer-bc',
  templateUrl: './footer-bc.component.html',
  styleUrls: ['./footer-bc.component.css']
})
export class FooterBcComponent {

  textElements: Array<string> = ['Dirección IP 190.144.71.250', 'Última visita Jueves 31 de Julio de 2019 10:06 a.m']
copyright: string[]=['Copyright © 2024 Grupo Bancolombia.' ]
}
